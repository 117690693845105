// 👇 It's important to not import @valu/react-valu-search directly here but
// only the subpackage `lazy`.  This ensures the main entry bundle won't get
// bloated by RVS.
import { LazyValuSearch, select } from "@findkit/pink-ocelot/lazy";

const loader = new LazyValuSearch({
    // import the file exporting the ValuSearch instance as the default export
    load: () => import("./search-ui"),
});

/**
 * Case: Outside button && outside input that both open fullscreen modal
 */
loader.init(() => {
    const mobileButton = select(
        "#valu-search-button-mobile",
        HTMLButtonElement,
    );
    const button = select("#valu-search-button", HTMLButtonElement);
    const input = select("#valu-search-input", HTMLInputElement);

    // this binds event listener to input focus event and triggers load
    // if the input had focus already when this code is run.
    // this can happen in lazy load
    loader.loadOnFocus(input);

    button.addEventListener(
        "click",
        (event) => {
            event.preventDefault();
            // The activate() method lazy loads the valu-search.js file and
            // calls .activate() on the ValuSearch instance as well as soon as
            // possible. On subsequent calls when the module is already loaded it
            // just activates the VS instance.
            loader.activate();

            // Since the lazy loading might take a second we add a class that
            // visualizes the loading state via CSS if it is not loaded yet.
            if (!loader.isLoaded()) {
                button.classList.add("loading");
            }
        },
        false,
    );

    mobileButton.addEventListener(
        "click",
        (event) => {
            event.preventDefault();
            loader.activate();

            if (!loader.isLoaded()) {
                button.classList.add("loading");
            }
        },
        false,
    );

    return (vs) => {
        // Once ready we can remove the loading indicator
        vs.bindInputAsOpener(input);
        button.classList.remove("loading");
        mobileButton.classList.remove("loading");
        vs.initModal();
    };
});
